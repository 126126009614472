<template>
  <div class="main-page text-detection">
    <custom-header></custom-header>
    <div class="desc">
      <h4>多媒体违规内容检查</h4>
      <p>第三代NLP人工智能技术，为媒体内容安全生产保驾护航</p>
    </div>
    <div class="input-box">
      <div v-if="resultData && resultData.content" v-html="resultData.content" class="input-content"></div>
      <textarea v-model="inputValue" v-else ref="inputRef" @change="changeInput"></textarea>
      <div class="clear-btn" @click="onClear" v-if="inputValue || resultData.content">清空</div>
    </div>
    <div class="result-panel">
      <h4>检测结果</h4>
      <div class="result-box-text">
        <ul v-if="resultData && resultData.textCheckDetailVoList && resultData.textCheckDetailVoList.length">
          <li v-for="(item, index) in resultData.textCheckDetailVoList" :key="index">
            <label :class="`word-${wordType[item.category]}`">{{item.comment}}</label>
            <span>{{item.word}}</span>
            <span class="tip">{{item.proposal ? '建议替换为' : '建议删除'}}</span>
            <span class="proposal" v-if="item.proposal">{{item.proposal}}</span>
          </li>
        </ul>
        <div class="no-data" v-if="!resultData || !resultData.textCheckDetailVoList">
          <img :src="resultData.content ? noErrorImg : noDataImg" />
          <p>{{resultData.content ? '本次检查未发现错误' : '暂无数据'}}</p>
        </div>
      </div>
    </div>
    <ul class="tip-list">
      <li class="tip-suspected">疑似错误</li>
      <li class="tip-warning">敏感词</li>
      <li class="tip-danger">严重错误</li>
    </ul>

    <van-button round type="info" class="choose-btn" :disabled="loading || !inputValue" @click="textCheck">严格检查</van-button>
    <custom-footer></custom-footer>
  </div>
</template>

<script>
import CustomHeader from "@/components/CustomHeader.vue";
import CustomFooter from "@/components/CustomFooter.vue";

import { DATA } from '@/remote';
import {CODE_CLASS} from '@/utils/const';

export default {
  name: "deep-detection",
  components: {
    "custom-header": CustomHeader,
    "custom-footer": CustomFooter,
  },
  data(){
    return {
      noDataImg: require('@/assets/img/no-data.png'),
      noErrorImg: require('@/assets/img/no-error.png'),
      wordType: CODE_CLASS['ERROR_TYPE_LEVEL'],
      loading: false,
      resultData: '',
      inputValue: '船夫把我们带到一大片莲花池里，那里的莲花太多了，真让人目不遐接，有的莲花含苞待放，好像害羞的小古娘；有的莲花亭亭玉立，如同一位仙女，真是出淤泥而不染。那儿的荷叶更是多姿多才。'
    };
  },
  mounted() {
    this.textCheck();
  },
  methods: {
    changeInput(e) {
      this.inputValue = e.target.value || '';
    },

    onClear() {
      if (this.resultData && this.resultData.content) {
        this.resultData = '';
        setTimeout(() => {
          this.$refs.inputRef.focus();
        }, 100)
      }
      this.inputValue = '';
    },

    textCheck() {
      this.loading = true;
      this.resultData = '';
      DATA.textCheck({content: this.inputValue}).then(res => {
        if (res.code == 200) {
          this.loading = false;
          this.resultData = res.data || {};
        }
        this.loading = false;
      })
    }
  }
};
</script>

<style lang="less">
  @import '../../assets/less/textDetection';
</style>
