export const CODE_CLASS = {
    ERROR_TYPE_LEVEL: {
        '严重错误': 'danger',
        '敏感词': 'warning',
        '疑似错误': 'suspected',
    },
};

export const CODE_CLASS_LIST = (()=>{
    let codeClassList = {};
    // 格式转换
    for(let key in CODE_CLASS){
        codeClassList[key] = [];
        for(let k in CODE_CLASS[key]){
            codeClassList[key].push({
                code: k,
                name: CODE_CLASS[key][k]
            });
        }
    }
    return codeClassList;
})();